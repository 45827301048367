import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "./common/Breadcrumb";
 import ScrollToTop from "react-scroll-up";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

import { FiChevronUp,FiCode, FiDatabase, FiServer, FiShield, FiCloud, FiUsers, FiCpu } from "react-icons/fi";
 
const ServiceList = [
  {
    icon: <FiCode />,
    title: "Custom Software Development",
    description:
      "We design and develop bespoke software solutions to fit your specific business needs, ensuring seamless integration and scalability for future growth.",
  },
  {
    icon: <FiCpu />,
    title: "AI & Machine Learning Solutions",
    description:
      "Leverage the power of Artificial Intelligence and Machine Learning to automate processes, predict trends, and optimize your business operations. Our AI solutions are designed to enhance decision-making and drive innovation.",
  },
  {
    icon: <FiDatabase />,
    title: "Database Optimization & Management",
    description:
      "Maximize the potential of your data with our database optimization services, ensuring fast, reliable, and secure data management for enhanced business operations.",
  },
  {
    icon: <FiServer />,
    title: "IT Infrastructure Design",
    description:
      "Build a robust IT infrastructure with our expert guidance, ensuring secure, scalable, and high-performance solutions that support your organization's needs.",
  },
  {
    icon: <FiShield />,
    title: "Cybersecurity & Risk Management",
    description:
      "Protect your business from digital threats with our comprehensive cybersecurity solutions. We identify vulnerabilities and implement strategies to secure your critical assets.",
  },
  {
    icon: <FiCloud />,
    title: "Cloud Solutions & Migration",
    description:
      "Unlock the full potential of cloud technology with our solutions. From seamless cloud migration to optimization, we help you scale securely and cost-effectively.",
  },
  {
    icon: <FiUsers />,
    title: "Consulting & IT Strategy",
    description:
      "Receive expert advice and strategic insights from our consultants. We help you align technology with your business goals for maximum impact and growth.",
  },

];

class Services extends Component {
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle="IT Consulting Services" />
        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrumb Area */}
        <Breadcrumb title={"IT Consulting Services"} />
        {/* End Breadcrumb Area */}

        {/* Start Service Area */}
        <div className="service-area ptb--120 bg_color--5">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center mb--30">
                  <h2>Our IT Consulting Services</h2>
                  <p>
                    Explore our comprehensive IT consulting solutions designed
                    to enhance your organization's technology capabilities.
                  </p>
                </div>
              </div>
            </div>
            <div className="row service-one-wrapper">
              {ServiceList.map((val, i) => (
                <div
                  className="col-xl-4 col-lg-4 col-md-6 col-sm-6 col-12"
                  key={i}
                >
                  {/* <a href="/service-details"> */}
                  <div className="service service__style--2">
                    <div className="icon">{val.icon}</div>
                    <div className="content">
                      <h3 className="title">{val.title}</h3>
                      <p>{val.description}</p>
                    </div>
                  </div>
                  {/* </a> */}
                </div>
              ))}
            </div>
          </div>
        </div>
        {/* End Service Area */}

        {/* More Service Sections */}
        {/* ... */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}

export default Services;
