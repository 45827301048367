import React, { useState } from "react";
import emailjs from "emailjs-com";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Alert from "react-bootstrap/Alert";

function ContactForm() {
  const [result, setResult] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_1mzu1rs",
        "template_r9qfapp",
        e.target,
        "2Y8TfZP3EZVTweef1"
      )
      .then(
        (response) => {
          e.target.reset(); // Clear the form
          toast.success("Email sent successfully"); // Show a success toast
          setResult(true); // Show the success message
        },
        (error) => {
          console.error("Email send error:", error);
          toast.error("Email could not be sent"); // Show an error toast
        }
      );
  };

  setTimeout(() => {
    setResult(false);
  }, 10000);

  return (
    <>
      <ToastContainer
        autoClose={10000}
        closeOnClick
        draggable
        hideProgressBar={false}
        newestOnTop={false}
        pauseOnFocusLoss
        pauseOnHover
        position="top-right"
        rtl={false}
        theme="dark"
      />
      <form action="" onSubmit={sendEmail}>
        <div className="rn-form-group">
          <input type="text" name="fullname" placeholder="Your Name" required />
        </div>

        <div className="rn-form-group">
          <input type="email" name="email" placeholder="Your Email" required />
        </div>

        <div className="rn-form-group">
          <input type="text" name="phone" placeholder="Phone Number" required />
        </div>

        <div className="rn-form-group">
          <input type="text" name="subject" placeholder="Subject" required />
        </div>

        <div className="rn-form-group">
          <textarea
            name="message"
            placeholder="Your Message"
            required
          ></textarea>
        </div>

        <div className="rn-form-group">
          <button
            className="rn-button-style--2 btn-solid"
            type="submit"
            value="submit"
            name="submit"
            id="mc-embedded-subscribe"
          >
            Submit Now
          </button>
        </div>

        <div className="rn-form-group">
          {result && (
            <p className="success-message">
              <Alert key={"success"} variant={"success"}>
                Your Message has been successfully sent. We will contact you
                soon.
              </Alert>
            </p>
          )}
        </div>
      </form>
    </>
  );
}

export default ContactForm;
