import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Breadcrumb from "../elements/common/Breadcrumb";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import ContactTwo from "../elements/contact/ContactTwo";

class Contact extends Component {
  static defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33,
    },
    zoom: 11,
  };

  render() {
    let title = "About",
      description =
        "Welcome to our Contact Us page! We value your feedback, inquiries, and suggestions. Whether you have a question about our products or services, need assistance with an order, or simply want to share your thoughts, we're here to help. At NXT, we believe in providing exceptional customer service and building strong relationships with our customers. We understand the importance of effective communication and strive to ensure that your experience with us is as smooth and pleasant as possible.";
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Contact" />
        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />
        {/* Start Breadcrump Area */}
        <Breadcrumb title={"Contact"} />
        {/* End Breadcrump Area */}
        {/* Start Breadcrump Area */}
        <div className="rn-about-area ptb--120 bg_color--1">
          <div className="rn-about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
                <div className="col-lg-12">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h2 className="title">{title}</h2>
                      <p className="description">{description}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="rn-contact-page ptb--120 bg_color--1">
          <ContactTwo />
        </div>

        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        <Footer />
      </React.Fragment>
    );
  }
}
export default Contact;
