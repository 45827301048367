import React, { Component } from "react";
import ContactForm from "./ContactForm";
import { FiMail } from "react-icons/fi";

class ContactTwo extends Component {
  render() {
    return (
      <div className="contact-form--1">
        <div className="container">
          <div className="row row--35 align-items-start">
            <div className="col-lg-6 order-1 order-lg-1">
              <div className="section-title text-left mb--50">
                <h2 className="title">Contact Us.</h2>
              </div>
              <div className="form-wrapper">
                <ContactForm />
              </div>
            </div>
            <div className="col-lg-6 order-1 order-lg-1">
              <div className="rn-address">
                <div className="icon">
                  <FiMail />
                </div>
                <div className="inner">
                  <h4 className="title">Email Address</h4>
                  <p>
                    <a href="mailto:contact@nxtcs.com">contact@nxtcs.com</a>
                  </p>
                  <p>
                  </p>
                </div>
              </div>
            </div>

    
          </div>
        </div>
      </div>
    );
  }
}
export default ContactTwo;
