import React, { useEffect, useRef  } from 'react'
import ScrollToTop from 'react-scroll-up'
import Slider from 'react-slick'
import { slideSlick } from '../page-demo/script'
import Header from '../component/header/Header'
import FooterTwo from '../component/footer/FooterTwo'
import CallAction from '../elements/callaction/CallAction'
import { FiChevronUp } from 'react-icons/fi'
import Helmet from '../component/common/Helmet'

const SlideList = [
  {
    textPosition: 'text-left',
    bgImage: '/assets/images/bg/it_consulting.jpg',
    category: 'IT Consulting & Digital Strategy',
    title: 'Transform Your Business with IT Consulting',
    description:
      'Leverage our industry expertise to develop strategic IT solutions that drive innovation, efficiency, and growth for your business.',
    buttonText: 'Schedule a Consultation',
    buttonLink: '/contact',
  },
  {
    textPosition: 'text-left',
    bgImage: '/assets/images/bg/it_staffing_talent.jpg',
    category: 'IT Staffing & Talent Solutions',
    title: 'Find Top IT Talent for Your Business',
    description:
      'Connect with skilled IT professionals through our tailored staffing solutions. Whether contract, full-time, or project-based, we have the right talent for you.',
    buttonText: 'Hire Now',
    buttonLink: '/services',
  },
  {
    textPosition: 'text-left',
    bgImage: '/assets/images/bg/ai.jpg',
    category: 'AI & Automation',
    title: 'Accelerate Growth with AI & Automation',
    description:
      'Unlock new efficiencies with AI-driven solutions. Automate workflows, enhance decision-making, and drive innovation with our AI consulting services.',
    buttonText: 'Discover AI Solutions',
    buttonLink: '/services',
  },
  {
    textPosition: 'text-left',
    bgImage: '/assets/images/bg/cloud_ai.jpg',
    category: 'Cloud & Digital Transformation',
    title: 'Empower Your Business with Cloud Solutions',
    description:
      'Seamlessly migrate, manage, and optimize your cloud infrastructure. Unlock agility and scalability with our cloud consulting services.',
    buttonText: 'Get Started',
    buttonLink: '/services',
  },
]

const Home = () => {
  const sliderRef = useRef(null)
  
  useEffect(() => {
    const interval = setInterval(() => {
      if (sliderRef.current) {
        sliderRef.current.slickNext()
      }
    }, 5000)
    return () => clearInterval(interval)
  }, [])

  return (
    <>
      <Helmet pageTitle='NXT' />
      <Header />
      <div className='slider-wrapper'>
        <div className='slider-activation'>
          <Slider className='rn-slick-dot dot-light' {...slideSlick} ref={sliderRef}>
            {SlideList.map((value, index) => (
              <div
                className='slide slide-style-2 d-flex align-items-center justify-content-center'
                key={`${index}${value.bgImage}`}
                style={{
                  backgroundImage: `url(${value.bgImage})`,
                  backgroundSize: 'cover',
                  backgroundPosition: 'center',
                  transition: 'background-image 0.5s ease-in-out',
                }}
                data-black-overlay='8'
              >
                <div className='container'>
                  <div className='row'>
                    <div className='col-lg-12'>
                      <div className={`inner ${value.textPosition}`}>
                        {value.category && <span>{value.category}</span>}
                        {value.title && <h1 className='title'>{value.title}</h1>}
                        {value.description && <p className='description'>{value.description}</p>}
                        {value.buttonText && (
                          <div className='slide-btn'>
                            <a className='rn-button-style--2 btn-solid' href={value.buttonLink}>
                              {value.buttonText}
                            </a>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
      <CallAction />
      <FooterTwo />
      <div className='backto-top'>
        <ScrollToTop showUnder={160}>
          <FiChevronUp />
        </ScrollToTop>
      </div>
    </>
  )
}

export default Home
