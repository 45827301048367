import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import Breadcrumb from "../elements/common/Breadcrumb";
import CounterOne from "../elements/counters/CounterOne";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class About extends Component {
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle="About Us" />

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />
        {/* Start Breadcrumb Area */}
        <Breadcrumb title={"About Us"} />
        {/* End Breadcrumb Area */}

        {/* Start About Us Area */}
        <div className="rn-about-area ptb--120 bg_color--1">
          <div className="rn-about-wrapper">
            <div className="container">
              <div className="row row--35 align-items-center">
           
                <div className="col-lg-12">
                  <div className="about-inner inner">
                    <div className="section-title">
                      <h2 className="title">About Us</h2>
                      <p className="description">
                      At NXT IT Consulting, we’re committed to helping businesses navigate the complexities of the digital world. With a passion for technology and a focus on client success, our goal is to provide tailored IT solutions that empower your business to thrive in today’s ever-evolving market.
                      </p>
                    </div>
                    <div className="row mt--30">
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <div className="about-us-list">
                          <h3 className="title">Our Mission</h3>
                          <p>
                          We strive to deliver innovative, cost-effective IT solutions designed specifically for your needs. By leveraging the latest technology, we aim to support your business growth, enhance operational efficiency, and provide sustainable solutions that give you a competitive edge.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-6 col-12">
                        <div className="about-us-list">
                          <h3 className="title">Our Team</h3>
                          <p>
                          At the heart of NXT IT Consulting is a team of skilled professionals who are experts in various facets of IT. Our diverse team brings years of experience, delivering high-quality solutions and exceptional customer service. We work closely with you, ensuring that your unique goals are met through cutting-edge, reliable technology.
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End About Us Area */}

        {/* Start Why Choose Us Section */}
        <div className="rn-counterup-area pb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="section-title text-center">
                  <h3 className="fontWeight500">Why Choose Us?</h3>
                </div>
              </div>
            </div>
            <CounterOne />
          </div>
        </div>
        {/* End Why Choose Us Section */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default About;
